import { useMemo, useState } from "react";
import { InvoiceApi } from "@practice/sdk";
import { orderBy } from "lodash";
import useSWR from "swr";

import { useAuth } from "@contexts/auth";
import { useCollection } from "@contexts/data";
import { useTaxTypes } from "@hooks/data/taxTypes";
import useLogger from "@hooks/use-logger";
import { useSDKApi } from "@hooks/use-sdk-api";
import { AppointmentType } from "@lib/data/schemas/appointment";
import { getOnlyTitleFromLineItem } from "@lib/invoices";
import { catchErrorSDK } from "@lib/utils/catch-error-client";

import { useContacts } from "./clients";

export const useInvoices = () => {
  const { taxTypes } = useTaxTypes();

  const { data: invoices, loading: loadingInvoices } = useCollection(
    "invoices",
    {
      groupPropName: "payeeId",
    }
  );
  const { active: clients, loading: loadingClients } = useContacts();

  const res = useMemo(() => {
    if (!clients || !invoices || !taxTypes.length) return;

    return invoices
      ?.map((invoice) => {
        const associatedTaxType = taxTypes.find(
          (taxType) => taxType.id === invoice.items?.[0]?.taxTypeId
        );
        const associatedContact = clients.find(
          (client) => client.id === invoice.contactId
        );

        return {
          ...invoice,
          date: invoice.dueDate || invoice.sendAt || invoice.createdAt,
          _type: "invoice",
          taxType: associatedTaxType,
          contact: associatedContact,
        };
      })
      ?.filter((invoice) => !!invoice.contact);
  }, [clients, invoices, taxTypes]);

  return {
    data: res,
    loading: loadingInvoices || loadingClients,
  };
};

/**
 * It gets the status used in FE, considering the invoice data to determine it.
 * The reconciliation invoice uses different data to determine the status, and
 * this function is used along with the ArtifactStatus icon component
 * */
export const getInvoiceStatus = (invoice: any) => {
  if (!invoice?.status) return "no-invoice";

  if (invoice?.isApproved && invoice.status !== "scheduled")
    return invoice.status;

  if (!invoice?.isApproved && invoice?.needsAttention) {
    if (invoice?.isFromPastCycle) return "rollover";
    return "review-needed";
  }
  if (!invoice?.isApproved && !invoice?.needsAttention) return "pre-approved";

  if (invoice.status === "scheduled") {
    if (invoice.invoiceMethod === "manual") {
      return "approved-manual";
    } else {
      return "approved-auto-charge";
    }
  }

  return invoice.status;
};

/**
 * It calls the API to get the invoice preview data
 * */
export const useReconciliationInvoicePreview = (props: {
  clientId: string;
  invoiceId: string;
}) => {
  const { oid } = useAuth();
  const { clientId, invoiceId } = props;
  const invoiceApi = useSDKApi(InvoiceApi);

  const { data, isLoading, error, mutate } = useSWR(
    `organizations/${oid}/clients/${clientId}/invoices/${invoiceId}`,
    async () => {
      if (!oid) return;
      return invoiceApi.getInvoice({
        organizationId: oid,
        clientId,
        invoiceId,
      });
    },
    { dedupingInterval: 600000, revalidateOnMount: true }
  );

  return {
    data: {
      ...data,
      status: getInvoiceStatus(data),
      client: data?.client,
      items:
        data?.items?.map((item) => ({
          ...item,
          title: getOnlyTitleFromLineItem(item?.title ?? ""),
          rate: (item?.rate ?? 0) * 100,
          isRollover: item?.isRollover ?? false,
        })) ?? [],
      missingOutcomes: orderBy(
        data?.missingOutcomes?.map((outcome) => ({
          ...outcome,
          appointmentId: outcome?.appointmentId ?? "",
          date: outcome?.date ?? new Date(),
          title: outcome?.title ?? "",
          status: (outcome?.status ?? "") as AppointmentType["status"],
          member: outcome?.member,
        })) ?? [],
        "date",
        "asc"
      ),
      isFromPastCycle: data?.isFromPastCycle ?? false,
      ...(data?.paidAt && { paidDate: data.paidAt }),
      ...(data?.sentAt && { sharedDate: data.sentAt }),
      ...(data?.dueDate && { scheduledDate: data.dueDate }),
    },
    loading: isLoading,
    error,
    mutate,
  };
};

export const useApproveInvoice = () => {
  const [loading, setLoading] = useState(false);
  const { logger } = useLogger("use-approve-invoice");
  const [errorApprove, setErrorApprove] = useState<string | null>(null);
  const invoiceApi = useSDKApi(InvoiceApi);

  const approveInvoice = async (
    oid: string,
    clientId: string,
    invoiceId: string
  ) => {
    setLoading(true);
    try {
      await invoiceApi.postApproveInvoice({
        organizationId: oid,
        clientId,
        invoiceId,
      });
      return true;
    } catch (error: any) {
      const errorMessage = await catchErrorSDK(
        error,
        "Failed to approve invoice"
      );
      logger.error({ error, errorMessage }, "Failed to approve invoice");
      setErrorApprove(errorMessage);
      return false;
    } finally {
      setLoading(false);
    }
  };

  const clearErrors = () => setErrorApprove(null);

  return {
    approveInvoice,
    loading,
    errorApprove,
    clearErrors,
  };
};
